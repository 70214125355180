<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="峰会名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入峰会名称" />
      </a-form-model-item>
      <a-form-model-item label="活动开始时间" prop="startTime" >
        <a-date-picker style="width: 100%" v-model="form.startTime" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="活动结束时间" prop="endTime" >
        <a-date-picker style="width: 100%" v-model="form.endTime" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
      <a-form-model-item :label="$t('价格')"  prop="price">
        <a-input v-model="form.price" @change="limitPrice()" suffix="¥"
                 :placeholder="$t('请输入')+$t('价格')"/>
      </a-form-model-item>


      <a-form-model-item label="福利规则" prop="summitRule" >
        <a-textarea v-model="form.summitRule" placeholder="请输入" />
      </a-form-model-item>

<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSummit, addSummit, updateSummit } from '@/api/driving-school/summit'
import Editor from "@/components/Editor";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        name: null,

        startTime: null,

        endTime: null,

        price: null,

        summitRule: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        startTime: [{ required: true, message: '请输入开始时间', trigger: 'blur' }],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        endTime: [{ required: true, message: '请输入结束时间', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    limitPrice() {
      let val = this.form.price.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val<0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.price = val
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        startTime: null,
        endTime: null,
        price: null,
        summitRule: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSummit({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSummit(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSummit(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
