import request from '@/utils/request'


// 查询峰会列表
export function listSummit(query) {
  return request({
    url: '/driving-school/summit/list',
    method: 'get',
    params: query
  })
}

// 查询峰会分页
export function pageSummit(query) {
  return request({
    url: '/driving-school/summit/page',
    method: 'get',
    params: query
  })
}

// 查询峰会详细
export function getSummit(data) {
  return request({
    url: '/driving-school/summit/detail',
    method: 'get',
    params: data
  })
}

// 新增峰会
export function addSummit(data) {
  return request({
    url: '/driving-school/summit/add',
    method: 'post',
    data: data
  })
}

// 修改峰会
export function updateSummit(data) {
  return request({
    url: '/driving-school/summit/edit',
    method: 'post',
    data: data
  })
}

// 删除峰会
export function delSummit(data) {
  return request({
    url: '/driving-school/summit/delete',
    method: 'post',
    data: data
  })
}
